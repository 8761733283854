$speed: 14s;
$wordCount: 4;

.morphing {
  position: relative;
  display: inline-block;
}

.content::before {
  display: inline-block;
  margin-left: 16px;
  animation: value $speed infinite ease-in-out;
  animation-delay: -$speed;
  content: '';
  visibility: hidden;

  @keyframes value {
    0% {
      content: 'sustainable ';
    }

    25% {
      content: 'different ';
    }

    50% {
      content: 'resilient ';
    }

    75% {
      content: 'contrarian ';
    }

    100% {
      content: 'sustainable ';
    }
  }
}

.word {
  position: absolute;
  top: 50%;
  left: 0;
  animation: word $speed infinite ease-in-out;
  transform: translateY(-50%);

  @for $i from 0 to $wordCount {
    &:nth-child(#{$i + 1}) {
      animation-delay: ($speed / ($wordCount) * $i) - $speed;
    }
  }

  @keyframes word {
    0%,
    5%,
    100% {
      filter: blur(0);
      opacity: 1;
    }

    20%,
    80% {
      filter: blur(1em);
      opacity: 0;
    }
  }
}
